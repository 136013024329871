export enum YandexMetrikaGoalsEnum {
  // Общая ошибка
  ErrorCommon = 'error_common',
  // Ошибки запроса new
  ErrorAppNew = 'error_app_new',
  // Ошибки запроса SetStatusWidgetDisplayed
  ErrorSetStatusWidgetDisplayed = 'error_set_status_widget_displayed',
  // Ошибки запроса GetBanks
  ErrorGetBanks = 'error_get_banks',
  // Ошибки запроса GetMortgageProducts
  ErrorGetMortgageProducts = 'error_get_mortgage_products',
  // Ошибки запроса GetApplicationInfo
  ErrorGetApplicationInfo = 'error_get_application_info',
  // Ошибки запроса PreapproveMortgageApplication
  ErrorPreapproveMortgageApplication = 'error_preapprove_mortgage_application',
  // Ошибки запроса GetOffers
  ErrorGetOffers = 'error_get_offers',
  // Ошибки запроса SelectOffer
  ErrorSelectOffer = 'error_select_offer',
  // Ошибки запроса GetProductDetails
  ErrorGetProductDetails = 'error_get_product_details',
  // Ошибки запроса SaveContacts
  ErrorSaveContacts = 'error_save_contacts',
  // Ошибки запроса SaveMortgageApplication
  ErrorSaveMortgageApplication = 'error_save_mortgage_application',
  // Ошибки запроса ApproveMortgageOffer
  ErrorApproveMortgageOffer = 'error_approve_mortgage_offer',
  // Ошибки запроса GetMortgagePaymentLink
  ErrorGetMortgagePaymentLink = 'error_get_mortgage_payment_link',
  // Ошибки запроса SetStatusBuyButtonPressed
  ErrorSetStatusBuyButtonPressed = 'error_set_status_buy_button_pressed',


  // Общая ошибка
  CommontResultError = 'commont_result_error',
  // Ошибки запроса New
  NewResultError = 'new_result_error',
  // Ошибки запроса GetCarBrands
  SetStatusWidgetDisplayedResultError = 'set_status_widget_displayed_result_error',
  // Ошибки запроса GetBanks
  GetBanksResultError = 'get_banks_result_error',
  // Ошибки запроса GetMortgageProducts
  GetMortgageProductsResultError = 'get_mortgage_products_result_error',
  // Ошибки запроса GetApplicationInfo
  GetApplicationInfoResultError = 'get_application_info_result_error',
  // Ошибки запроса PreapproveMortgageApplication
  PreapproveMortgageApplicationResultError = 'preapprove_mortgage_application_result_error',
  // Ошибки запроса GetOffers
  GetOffersResultError = 'get_offers_result_error',
  // Ошибки запроса SelectOffer
  SelectOfferResultError = 'select_offer_result_error',
  // Ошибки запроса GetProductDetails
  GetProductDetailsResultError = 'get_product_details_result_error',
  // Ошибки запроса SaveContacts
  SaveContactsResultError = 'save_contacts_result_error',
  // Ошибки запроса SaveMortgageApplication
  SaveMortgageApplicationResultError = 'save_mortgage_application_result_error',
  // Ошибки запроса ApproveMortgageOffer
  ApproveMortgageOfferResultError = 'approve_mortgage_offer_result_error',
  // Ошибки запроса GetMortgagePaymentLink
  GetMortgagePaymentLinkResultError = 'get_mortgage_payment_link_result_error',
  // Ошибки запроса SetStatusBuyButtonPressed
  SetStatusBuyButtonPressedResultError = 'set_status_buy_button_pressed_result_error',

}

